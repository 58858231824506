import { useStaticQuery, graphql } from "gatsby"
// eslint-disable-next-line max-lines-per-function
export const useSiteMetadata = () => {

  const data = useStaticQuery(
    graphql`
      query SiteMetaData {
        logo: file(name: { eq: "maskable_icon_x512" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        seoImage: file(name: { eq: "maskable_icon_x512" }) {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 1200
              height: 630
            )
          }
        }
        site {
          siteMetadata {
            title
            description
            keywords
            author
            siteUrl
            menuLinks {
              name
              link
              type
              location
              subMenu {
                name
                link
                type
              }
            }
            socialLinks {
              name
              link
              location
            }
            notFound {
              consternation
              condolences {
                expression1
                expression2
              }
            }
            contactPlaceholder {
              name
              email
              subject
              comments
            }
          }
        }
      }
    `
  )


  const logo = data.logo
  const seoImage = data.seoImage
  const metaData = data.site.siteMetadata
  const twitterLink = data.site.siteMetadata.socialLinks
    .filter((socialLink: any ) => socialLink.name.toLowerCase() === "twitter")
    .map((socialLink: any) => {
      return socialLink.link
    })
  const twitterUsername = twitterLink.length
    ? twitterLink[0]
        .toString()
        .toLowerCase()
        .replace("https://www.twitter.com/" && "https://twitter.com/", "@")
    : "Unknown"

  return { ...metaData, logo, seoImage, twitterUsername }
}
