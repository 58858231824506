import React, { useState, createContext, Dispatch, ReactNode } from "react"

type NavProviderProps = {
  children: ReactNode;
};

export const NavContext = createContext<([boolean, Dispatch<React.SetStateAction<boolean>>])> ({} as any)

export const NavProvider = ({ children }: NavProviderProps) => {
  const [isNavOpen, setIsNavOpen] = useState(false)

  return (
    <NavContext.Provider value={[isNavOpen, setIsNavOpen]}>
      {children}
    </NavContext.Provider>
  )
}
