import React, { useState, createContext, Dispatch, ReactNode } from "react"

export const HomeContext = createContext<([boolean, Dispatch<React.SetStateAction<boolean>>])> ({} as any)

type HomeProviderProps = {
	children: ReactNode;
};

export const HomeProvider = ({ children }: HomeProviderProps) => {
  const [isHome, setIsHome] = useState(false)
  return (
    <HomeContext.Provider value={[isHome, setIsHome]}>
      {children}
    </HomeContext.Provider>
  )
}
