import { useStaticQuery, graphql } from "gatsby"
export const useswhwsysConfig = () => {
  const { swhwsysConfig } = useStaticQuery(
    graphql`
      query {
        swhwsysConfig {
          contentPath
          assetPath
          displaySiteLogo
          displaySiteTitle
          displaySiteLogoMobile
          displaySiteTitleMobile
          invertSiteLogo
          useStickyHeader
          useSocialLinks
          useColorMode
          footerContentLocation
        }
      }
    `
  )
  return swhwsysConfig
}
