/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from 'react'

type ContentContainerProps = {
	children: React.ReactNode;
};

const ContentContainer = ({ children }: ContentContainerProps) => {
  return (
    <div
      sx={{
        maxWidth: ["90%", "90%", "60%", "50%"],
        my: 0,
        mx: "auto",
        px: 3,
        variant: "variants.contentContainer",
      }}
    >
      {children}
    </div>
  )
}

export default ContentContainer
