module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/runner/work/SWHWSys-frontend/SWHWSys-frontend/node_modules/gatsby-remark-images","id":"c06e11bd-d726-5deb-beef-2794587576a8","name":"gatsby-remark-images","version":"4.2.0","pluginOptions":{"plugins":[],"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent","quality":50,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"defaultLayouts":{"default":"/home/runner/work/SWHWSys-frontend/SWHWSys-frontend/themes/gatsby-theme-swhwsys-core/src/components/layout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-relative-images","options":{}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent","quality":50}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"content/assets"}},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-reading-time"},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-external-links"}],"remarkPlugins":[null],"rehypePlugins":[],"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/SWHWSys-frontend/SWHWSys-frontend/packages/SoftwareHardwareSystems"},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent","quality":50,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"prismPreset":"oceanic-next","preset":"@theme-ui/preset-tailwind"},
    },{
      plugin: require('../../../themes/gatsby-theme-swhwsys-core/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content/docs","assetPath":"content/assets","displaySiteLogo":true,"displaySiteTitle":true,"displaySiteTitleMobile":false,"footerContentLocation":"left"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Software Hardware Systems","short_name":"SHS","start_url":"/","background_color":"#edf2f7","theme_color":"#3182ce","display":"minimal-ui","icon":"content/assets/maskable_icon_x512.png","cache_busting_mode":"none","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/icons/*"]}},
    }]
